.table {
    text-align: center;
}

.addBus:hover {
    color: #4BB543;
    cursor: pointer;
}

.trash:hover {
    color: #C70000;
    cursor: pointer;
}

.betaTrue:hover {
    cursor: pointer;
    color: #22bb33;
}

.betaFalse:hover {
    cursor: pointer;
    color: #bb2124;
}
